import React from "react";
import {Trans, useTranslation, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import GatsbySlideshow from "../../../components/carousel/gatsby-slideshow/GatsbySlideshow";
import Solution from "../../../components/solutions/Solution";
import ElectronicLockLineup from "../../../components/solutions/ElectronicLockLineup";
import athFac from './images/applications/ath-fac.jpg';
import libStorage from './images/applications/lib-storage.jpg';
import juiceBar from './images/applications/juice.jpg';
import CustomerVideo from "../../../components/solutions/CustomerVideo";
import posterSolution from "./images/poster-education.jpg";
import {returnLocaleURL} from "../../../utils/utils";

const IndexPage = (data) => {

  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  let sl = context.language;
  const d = data.data.Slides.edges;
  const images = [];
  for(let i= 0; i<= d.length-1; i++) {
    images.push(d[i].node.childImageSharp);
  }
  const solutions = [
    {
      img: athFac,
      label: 'athletic_facilities',
      alt: 'athletic_facilities',
      bg: 'dark '
    },
    {
      img: libStorage,
      label: 'library_storage',
      alt: 'library_storage',
      bg: 'light'
    },
    {
      img: juiceBar,
      label: 'juicebar_charging_lockers',
      alt: 'juicebar_charging_lockers',
      bg: 'light'
    }
  ]
  const overlays = [
    '<span>canada</span><br>' +
    '<h2>Mount Royal University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Iowa State University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Ohio State University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>University of Texas</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis Keypad</a></p>',

    '<span>Malaysia</span><br>' +
    '<h2>Alfa College</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Versa Mini Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Colorado College</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Cue Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Loyola University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Princeton University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Cue Keypad</a></p>'

  ];
  return (
      <Layout>
        <Seo title={t('solutions_education')}
        description={t('des_solutions_education')}/>
        <div className="solutions">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/industries/hero-education.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Education"
            />
            <h1>
              <Trans>
                education
              </Trans>
              <span>
                <Trans>education_hero_subhead</Trans>
              </span>
            </h1>
          </section>
          <section className="customer-video healthcare">
            <div className="container">
              <div className={'grid-container'}>
                <h2><Trans>education_subhero_title</Trans></h2>
                <ul>
                  <li><Trans>education_subhero_list_l1</Trans></li>
                  <li><Trans>education_subhero_list_l2</Trans></li>
                  <li><Trans>education_subhero_list_l3</Trans></li>
                  <li><Trans>education_subhero_list_l4</Trans></li>
                  <li><Trans>education_subhero_list_l5</Trans></li>
                  <li><Trans>education_subhero_list_l6</Trans></li>
                </ul>
              </div>
              <div className="video-holder">
                <StaticImage
                    src="./images/education.jpg"
                    quality={100}
                    width={508}
                    height={312}
                    formats={["auto", "webp", "avif"]}
                    loading={'lazy'}
                    alt="education"
                />
              </div>
            </div>
          </section>
          <ElectronicLockLineup label={'education_lineup_label'} showMech={false} showPivot={true} showCurve={true} showAspire={true} showVersa={true} showCTA={true} />
          <section className="industry-carousel">
            <div className="container">
              <h2><Trans>education_carousel</Trans></h2>
              <GatsbySlideshow images={images} overlays={overlays} />
            </div>
          </section>
          <section className="customer-video healthcare">
            <CustomerVideo
                poster={posterSolution}
                title={'education_customer_video_title'}
                copy={'education_customer_video_copy'}
                vidURL={'https://cdn.mediavalet.com/usva/digilock/N2teqf7R8kauunHroklILg/iQhCqmYu3k-vmJ89jIOXhg/Widescreen720p/education.mp4'}
            />
          </section>
          <section className="industry-uses">
            <div className="container">
              <h2><Trans>popular_keyless</Trans></h2>
              <Solution items={solutions} />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    Slides: allFile(
    filter: {relativeDirectory: {eq: "solutions/education"}}
    sort: {fields: [name], order: ASC}
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
      }
    }
  }
  
  }
`;
